import React from "react";
import Layout from "./Layout";
import {BrowserRouter, Switch, Route} from "react-router-dom";
import MainPage from "./MainPage";
import ProductPage from "./ProductPage";
import PrivacyPage from "./PrivacyPage";
import NotFoundPage from "./NotFoundPage";

const App = () => (

  <BrowserRouter>
    <Layout>
      <Switch>
        <Route exact path="/">
          <MainPage/>
        </Route>
        <Route path="/edition/:handle" component={ProductPage}/>
        <Route path="/privacy" component={PrivacyPage}/>

        <Route path='*' exact component={NotFoundPage}/>
      </Switch>
    </Layout>
  </BrowserRouter>
)

export default App;
