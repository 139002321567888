import React, {Component} from "react"
import {layoutGrid, shopifyClient} from "../utils";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import LoadingAnimation from "./LoadingAnimation";
import {listQuery} from "../queries";

class MainPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      products: [],
      error: null
    }

    this.grid = null;
  }

  componentDidMount() {
    this.loadProducts()
      .then(() => {
        this.grid = layoutGrid('.product-grid', '.product-item')
      })
  }

  handleImageLoaded = () => {
    if (this.grid) {
      this.grid.layout();
    }
  }

  loadProducts = async () => {
    this.setState({
      loading: true
    })
    try {
      const res = await shopifyClient.request(listQuery);
      console.log(res)
      this.setState({
        products: res.products.edges.map(e => e.node),
        loading: false
      })
    } catch (e) {
      console.error(e);
      this.setState({
        error: "Failed to load editions / Editionen konnten nicht geladen werden",
        loading: false
      })
    }
  }

  render() {
    const {products, loading, error} = this.state;

    if (error) {
      return (<div className="error">{error}</div>)
    }

    const productItems = products.map(product => {
      const metaWidth = product.metafield?.key === "preview_width" && product.metafield.value;
      const width = metaWidth ? Number(metaWidth.value) : 450;
      const featuredImage = product.images?.edges[0]?.node
      // const height = (product.featuredImage.height / product.featuredImage.width) * width;

      return (
        <div
          key={product.id}
          className="product-item"
          style={{
            width,
          }}>
          <Link to={`/edition/${product.handle}`}>
            <img
              style={{width}}
              src={featuredImage.url}
              alt={`Image of V8 edition by ${product.title}`}
              onLoad={this.handleImageLoaded}
            />
          </Link>
          <div className="product-title">
          <span className="title-shell">
                      {product.title}
          </span>
          </div>
        </div>
      )
    })

    return (
      <React.Fragment>
        <Helmet>
          <title>V8 Editionen</title>
          <meta
            name="description"
            content={`V8 Editionen`}
          />
        </Helmet>
        {loading && <LoadingAnimation/>}
        <div
          className="product-grid"
        >
          {productItems}
        </div>
        <br/><br/>
        <p>Impressum</p>
        <p>
          V8 Plattform
          für neue Kunst
          Viktoriastrasse 8
          76133 Karlsruhe
        </p>
        <p>
          <a href="http://www.viktoria8.de">www.viktoria8.de</a>, <a
          href="mailto:info@viktoria8.de">info@viktoria8.de</a>, <a
          href="mailto:presse@viktoria8.de">presse@viktoria8.de</a>
        </p>
        <p>Verantwortliche Mitglieder: Jens Braun, Franziska Degendorfer, Philipp Joswig, Istihar Kalach und Daniel
          Wogenstein</p>
        <p><Link to="/privacy">Datenschutz/Privacy</Link></p>

      </React.Fragment>
    )
  }
}

export default MainPage
