import React from "react"

const LoadingAnimation = ({}) => {
  return (
    <div className="loading">
    <span>Lädt / Loading</span>
    </div>
  )
}

export default LoadingAnimation
