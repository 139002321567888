import React from "react"
import {Link} from "react-router-dom";

const Layout = ({children}) => {
  return (
    <div className="layout-root">
      <Link to="/">
        <svg
          preserveAspectRatio="none"
          className="header-svg"
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 939.28 438.45">
          <path
            d="M636,302.77c0-53.92,54.48-64.59,73.34-64.59,30.38,0,73.86,20.15,73.86,64,0,39.69-37.72,64-72.29,64-29.34,0-74.91-19-74.91-63.4m8.9-182.49c0-26.67,23.57-48,64.44-48,37.71,0,64.43,20.15,64.43,49.18,0,26.67-24.62,47.4-62.34,47.4-43,0-66.53-21.92-66.53-48.58m-71.8,82.94c-26.72,8.29-93.25,27.85-93.25,103.69,0,34.36,15.72,65.17,42.44,87.09,28.29,23.11,72.81,44.44,190.16,44.44,62.86,0,134.11-7.11,179.16-40.88,29.86-22.51,47.67-58.66,47.67-90.65,0-21.93-6.81-73.47-90.1-103.69,21-10.67,72.29-36.15,72.29-89.47,0-26.66-15.72-53.33-42.43-73.47C855.46,22.51,815.13,0,700.4,0c-142,0-203.26,58.06-203.26,113.17,0,59.25,52.39,80.58,76,90.06M155.59,7.11H0L181.78,431.34H343.65L522.29,7.11H370.89L264.55,312.84H263.5Z"/>
        </svg>
        <svg
          preserveAspectRatio="none"

          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 498.78 80.04"
          className="header-svg"
        >
          <path d="M0,0H65.21V16.81H24.38V29.33H62.25V45.39H24.38V60.91h42V78.74H0Z"/>
          <path
            d="M131,0V78.74H110.56V70.31q-4.29,5.37-7.84,7.25A22.35,22.35,0,0,1,92.2,80q-11.65,0-17.81-8.86T68.24,49.63q0-14.18,6.8-21.7A22.25,22.25,0,0,1,92.3,20.41a24,24,0,0,1,9.27,1.72A21,21,0,0,1,109,27.29V0ZM109.11,50.06q0-6.72-2.84-10a9.09,9.09,0,0,0-7.2-3.3,7.92,7.92,0,0,0-6.39,3.17Q90.1,43.08,90.1,50.54q0,7,2.66,10.23A8.19,8.19,0,0,0,99.34,64a8.79,8.79,0,0,0,7-3.27Q109.12,57.48,109.11,50.06Z"/>
          <path d="M138,0h21.86V14.88H138Zm0,21.7h21.86v57H138Z"/>
          <path
            d="M193.16,0V21.7h12v16h-12V57.9c0,2.43.23,4,.7,4.83a4,4,0,0,0,3.76,1.82,23.8,23.8,0,0,0,6.77-1.39L206,78.26A71.47,71.47,0,0,1,190.85,80q-8.16,0-12-2.09a12.8,12.8,0,0,1-5.72-6.36q-1.84-4.26-1.85-13.81v-20h-8.06v-16h8.06V11.23Z"/>
          <path d="M210.1,0H232V14.88H210.1Zm0,21.7H232v57H210.1Z"/>
          <path
            d="M235.94,50.38q0-13.05,8.81-21.51t23.79-8.46q17.13,0,25.89,9.94,7,8,7,19.71,0,13.15-8.73,21.56T268.6,80q-13.76,0-22.24-7Q235.94,64.41,235.94,50.38Zm21.91-.05q0,7.62,3.09,11.28a10.12,10.12,0,0,0,14.26,1.26,10,10,0,0,0,1.26-1.26q3-3.6,3-11.55,0-7.41-3.06-11a9.55,9.55,0,0,0-7.58-3.62A9.92,9.92,0,0,0,261,39.13Q257.85,42.8,257.85,50.33Z"/>
          <path
            d="M305.12,21.7h20.36V31a30.51,30.51,0,0,1,9.23-8.14,24.37,24.37,0,0,1,11.39-2.44q9.07,0,14.21,5.4t5.13,16.68V78.74h-22V47.37q0-5.37-2-7.6a7.11,7.11,0,0,0-5.58-2.23,8,8,0,0,0-6.45,3q-2.48,3-2.47,10.8v27.4H305.12Z"/>
          <path
            d="M434.85,55.64H391q.6,5.27,2.85,7.85a10.35,10.35,0,0,0,8.27,3.7,12.38,12.38,0,0,0,6.12-1.61,14.08,14.08,0,0,0,3.82-3.6l21.54,2q-5,8.59-11.93,12.33t-20,3.73q-11.34,0-17.83-3.2a26.08,26.08,0,0,1-10.77-10.15,30.6,30.6,0,0,1-4.27-16.35q0-13.38,8.56-21.65T401,20.41q12.25,0,19.34,3.71a25.06,25.06,0,0,1,10.8,10.74q3.71,7,3.7,18.31ZM412.61,45.17q-.63-6.33-3.41-9.08a10.69,10.69,0,0,0-15.1.8c-.17.19-.34.39-.5.6-1.32,1.72-2.17,4.28-2.52,7.68Z"/>
          <path
            d="M438.46,21.7h20.36V31a30.28,30.28,0,0,1,9.24-8.14,24.36,24.36,0,0,1,11.38-2.44q9.07,0,14.21,5.4t5.13,16.68V78.74h-22V47.37q0-5.37-2-7.6a7.11,7.11,0,0,0-5.58-2.23,8,8,0,0,0-6.45,3q-2.47,3-2.47,10.8v27.4H438.46Z"/>
        </svg>
      </Link>
      {children}
    </div>
  )
}

export default Layout
