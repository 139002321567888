import React, {Component} from "react"
import {shopifyClient} from "../utils";
import classNames from "classnames";
import {Helmet} from "react-helmet";
import LoadingAnimation from "./LoadingAnimation";
import {cartCreateMutation, itemQuery} from "../queries";


class ProductPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      generallyAvailable: false,
      product: null,
      selectedVariant: null,
      checkoutLoading: false,
      error: null
    }
  }

  componentDidMount() {
    this.fetchProduct();
  }

  fetchProduct = () => {

    shopifyClient.request(itemQuery, {handle: this.props.match.params.handle})
      .then(res => {
        console.log(res)
        const product = {
          ...res.product,
          images: res.product.images.edges.map(i => ({
            ...i.node,
            src: i.node.url
          })),
          variants: res.product.variants.edges.map(v => ({
            ...v.node,
            price: v.node.price.amount,
            available: v.node.availableForSale,
            image: {
              ...v.node.image,
              src: v.node.image?.url
            }
          }))
        }
        console.log({product})
        const stateData = {
          product,
          ready: true,
          generallyAvailable: product.availableForSale,
        }
        if (product.variants.length > 1) {
          const firstAvailableVariant = product.variants.find(v => v.available);
          if (firstAvailableVariant) {
            stateData.selectedVariant = firstAvailableVariant.id;
          } else {
            stateData.generallyAvailable = false;
          }
        }
        this.setState(stateData);
      })
      .catch(e => {
        console.error(e)
        this.setState({
          error: "Edition not found / Edition wurde nicht gefunden"
        })
      })
  }

  handleVariantChange = (e) => {
    this.setState({
      selectedVariant: e.target.value
    })
  }

  handleBuy = async () => {
    this.setState({
      checkoutLoading: true
    })
    try {
      let variantId = null
      const {product, selectedVariant} = this.state;
      const {variants} = product;
      console.log({
        variants, selectedVariant
      })
      if (variants.length > 1) {
        const selectedVariantItem = variants.find(v => v.id === selectedVariant);
        variantId = selectedVariantItem.id;
      } else {
        variantId = variants[0].id
      }
      console.log('Creating cart...')
      const res = await shopifyClient.request(cartCreateMutation, {
        itemId: variantId
      })
      if (res.cartCreate?.cart?.checkoutUrl) {
        window.location.assign(res.cartCreate?.cart?.checkoutUrl);
      } else {
        alert("Checkout error, could not retrieve checkout!.")
        this.setState({
          checkoutLoading: false,
          error: res.cartCreate?.cart?.userErrors.map(e => `${e.field}: ${e.message}`).join(" ")
        })
      }
    } catch (e) {
      this.setState({
        checkoutLoading: false,
        error: "Checkout konnte nicht erstellt werden / Checkout could not be created"
      })
      console.error(e)
      alert("Checkout error - please try again later.")
    }

  }

  render() {
    const {
      ready,
      generallyAvailable,
      product,
      selectedVariant,
      checkoutLoading,
      error
    } = this.state
    if (error) {
      return (<div className="error">
        {error}
      </div>)
    }
    if (!ready) {
      return (<LoadingAnimation/>)
    }
    const {variants} = product
    const selectedVariantItem = ready && variants.length > 0 && variants.find(v => v.id === selectedVariant);
    const imageItems = [];
    const metaWidth = product.metafield?.key === "preview_width" && product.metafield.value;
    const width = metaWidth ? Number(metaWidth.value) : 450;


    // Unique product (has variants)
    if (product.variants.length > 1) {
      product.variants.forEach((variant, i) => {
        const height = (variant.image.height / variant.image.width) * width;
        let available = true;
        if (variants && variants[i] && !variants[i].available) {
          available = false
        }
        imageItems.push(
          <div key={variant.id} className="image-grid-item multi">
            <div
              className="image-wrapper multi"
            >
              <img alt={`Image of V8 Edition ${product.title} Nr. ${variant.title}`}
                   src={variant.image.src}/>
            </div>
            <div
              className="variant-number"
              style={{
                textDecoration: available ? 'none' : 'line-through'
              }}
            >Nr.{variant.title}</div>
          </div>
        )
      })
    } else {
      product.images.forEach(image => {
        imageItems.push(
          <div key={image.id} className="image-grid-item">
            <div
              className="image-wrapper"
            >
              <img alt={`Image of V8 Edition ${product.title}`} src={image.src}/>
            </div>
          </div>
        )
      })
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>{`${product.title}`} | V8 Editionen</title>
          <meta
            name="description"
            content={`${product.description}`}
          />
          {/* Product SEO Snippet */}
          <script type="application/ld+json">
            {`
            {
              "@context": "http://schema.org",
              "@type": "Product",
              "name": "${product.title} | V8 Editionen",
              "image": "${product.images[0].src}",
              "description": "${product.description}",
              "mpn": "${product.id}",
              "url": "${window.location.href}",
              "offers":{
                "@type": "Offer",
                "priceCurrency": "EUR",
                "price": "${selectedVariantItem ? selectedVariantItem : variants[0].price}",
                "availability": "${generallyAvailable ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock'}"
              }
            }
          `}
          </script>
        </Helmet>
        <div
          className="product-image-grid"
        >
          {imageItems}
        </div>
        <div className="product-description" dangerouslySetInnerHTML={{__html: product.descriptionHtml}}/>
        <br/>
        {/*{ !ready && <progress/> }*/}
        {ready &&
          <div className="order-widget">
            {/* Buy single item */}
            {generallyAvailable && variants.length <= 1 &&
              <>
                {variants[0].price}€<br/> <br/>
                <button disabled={checkoutLoading} onClick={this.handleBuy}>Kaufen / Buy</button>
              </>
            }
            {/* Single item out of stock */}
            {
              !generallyAvailable &&
              <span>Nicht mehr verfügbar / No longer available</span>
            }
            {/* Variant buy  */}
            {generallyAvailable && variants.length > 1 &&
              <>
                {selectedVariantItem.price}€<br/> <br/>
                Edition Nr.{" "}<select
                name="variant-select"
                id="variant-select"
                value={selectedVariant}
                onChange={this.handleVariantChange}
              >
                {
                  variants.map(v =>
                    <option
                      key={v.id}
                      value={v.id}
                      disabled={!v.available}
                    >
                      {v.title}
                    </option>
                  )
                }
              </select>{" "}
                <button disabled={checkoutLoading} onClick={this.handleBuy}>Kaufen / Buy</button>
              </>
            }
            <br/><br/>
            {checkoutLoading && <progress/>}
          </div>
        }
      </React.Fragment>
    )
  }

}

export default ProductPage
