import React from "react"

const NotFoundPage = ({}) => {
  return (
    <div className="error">
        Seite nicht gefunden / Page not found
    </div>
  )
}

export default NotFoundPage
