import {gql} from "graphql-request";

export const listQuery = gql`
    {
        products(first: 100){
            edges{
                node{
                    id
                    title
                    handle
                    images(first: 1){
                        edges{
                            node{
                                url
                            }
                        }
                    }
                    metafield(namespace: "global", key: "preview_width"){
                        key
                        value
                    }
                }
            }
        }
    }
`

export const itemQuery = gql`
    query ProductByHandle($handle: String!){
        product(handle: $handle){
            id
            images(first: 1){
                edges {
                    node {
                        url
                    }
                }
            }
            availableForSale
            title
            variants(first: 100){
                edges {
                    node {
                        availableForSale
                        id
                        title
                        image {
                            width
                            height
                            url
                        }
                        price {
                            amount
                            currencyCode
                        }
                    }
                }
            }
            metafield(namespace: "global", key: "preview_width"){
                key
                value
            }
        }
    }
`

export const cartCreateMutation = gql`
    mutation cartCreateMutation($itemId: ID!){
        cartCreate(input: {lines: [{
            merchandiseId: $itemId,
            quantity: 1
        }]}){
            cart{
                checkoutUrl
            }
            userErrors {
                field
                message
            }
        }
    }
`
