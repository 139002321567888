import Packery from "packery";
import { GraphQLClient} from 'graphql-request'

export const layoutGrid = (gridTarget, gridItemTarget) => {
  const grid = document.querySelector(gridTarget);
  return new Packery(grid, {
    itemSelector: gridItemTarget,
    gutter: 20
  });
}

// Shopify Client
export const shopifyClient = new GraphQLClient(
  `https://${process.env.REACT_APP_SHOPIFY_STORE_URL}/api/2021-07/graphql.json`,
  {
    headers: {
      "X-Shopify-Storefront-Access-Token": process.env.REACT_APP_SHOPIFY_STOREFRONT_TOKEN
    },
  }
);
